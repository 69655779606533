<template>
<div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
  <div id="page-content" v-if="!viLoader">
    <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>
    <!-- <b-spinner v-if="showToast" variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner> -->
          <!-- <h1 class="hdrtitle ml-2">School Hub</h1> -->
          <div class="row mt-2">
            <div class="col-md-12 col-lg-12 col-sm-12">
              <div class="widjet p-3 m-2">
                <div class="row row-in">
                 
                  <div class="col-lg-4 col-sm-6 row-in-br">
                    <div
                      class=" row setupsect  my-1"
                       @click.prevent="redirectRoute('/admission/school/profile')"
                    >
                      <div class="col-auto" style="padding-right:0">
                        <div class="iconbg bg-light-green">
                          <i class="fas fa-school text-green"></i>
                        </div>
                      </div>
                      <div class="col">
                        <h5 class="text-muted mt-2">
                          Setup Your School
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 row-in-br" :style="!isEnableBranch  ? 'cursor: not-allowed;pointer-events: none;cursor: no-drop;background-color: #f7eeee;' : 'pointer-events: visible;left: 50px;'">
                    <div :style="!isEnableBranch  ? 'cursor: not-allowed;pointer-events: none;cursor: no-drop;background-color: #f7eeee;' : 'pointer-events: visible;'"
                      class=" row setupsect my-1"
                      @click.prevent="redirectRoute('/admission/school/branch')"
                    >
                      <div class="col-auto" style="padding-right:0" >
                        <div class="iconbg bg-light-magenta">
                          <i class="fas fa-user-cog text-magenta"></i>
                        </div>
                      </div>
                      <div class="col" :style="!isEnableBranch  ? 'cursor: not-allowed;pointer-events: none;cursor: no-drop;background-color: #f7eeee;' : 'pointer-events: visible;'">
                        <h5 class="text-muted mt-2" :style="!isEnableBranch  ? 'cursor: not-allowed;pointer-events: none;cursor: no-drop;background-color: #f7eeee;' : 'pointer-events: visible;'">
                          Manage School Branch
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 row-in-br" style="left: 50px;">
                    <div
                      class=" row setupsect my-1"
                      @click.prevent="redirectRouteToViewSchool"
                    >
                    <!--  -->
                      <div class="col-auto" style="padding-right:0">
                        <div class="iconbg bg-light-magenta">
                          <i class="fas fa-user-cog text-magenta"></i>
                        </div>
                      </div>
                      <div class="col">
                        <h5 class="text-muted mt-2" @click.prevent="redirectRouteToViewSchool">
                          
                          Preview your School in Vidhyaan
                        </h5>
                        <!-- <span>dddd</span> -->
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="row countsection" style="margin-top: 8px;">
            <div class="col-lg-12">
              <div class="countsect p-2 ml-2 mr-2">
                <ul class="dispflex" >
                  <li>
                    <span :class="getObjCounts.academicYear > 0 ? 'badge contbadge' : 'badge contbadgedisb'">
                      <i style="font-size: small;" :class="getObjCounts.academicYear > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i>
                      </span>Academic Year
                  </li>
                 
                  <li>
                    <span :class="getObjCounts.teacher > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="getObjCounts.teacher > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'"></i></span
                    >Teachers
                  </li>
                  <li>
                    <span :class="getObjCounts.schoolType > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="getObjCounts.schoolType > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i></span
                    >School Type
                  </li>
                 
                  <li>
                    <span :class="getObjCounts.class > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="getObjCounts.class > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'"></i></span
                    >Classrooms
                  </li>
                  <li>
                    <span :class="getObjCounts.term > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="getObjCounts.term > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'"></i></span
                    >Grade & Terms
                  </li>
                   <li>
                    <span :class="getObjCounts.student > 0 ? 'badge contbadge' : 'badge contbadgedisb'"
                      ><i style="font-size: small;" :class="getObjCounts.student > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'"></i></span
                    >Students
                  </li>
                 
                 
                 
                </ul>
              </div>
            </div>
          </div>
         

          <div class="row mt-2">
            <div class="col-md-4">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0"
                  @click.prevent="redirectRoute('/admission/academicYear')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 1</span>
                  <span class="admcount org_bg">{{ getObjCounts.academicYear }}</span><span style="display: block;line-height:18px;">Academic Year</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="margin-top: -12px;"></div>
                  Setup and Conclude the Academic Year session
                  <!-- Academic Year
                  <span><br />Setup and Conclude the Academic Year session</span> -->
                </div>
              </div>
            </div>
           

            <div class="col-md-4"  :style="getObjCounts.academicYear == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="getObjCounts.academicYear > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                 @click.prevent="redirectRoute('/admission/teacher')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 2</span>
                  <span class="admcount org_bg">{{ getObjCounts.teacher }}</span
                  ><span class="admcount org_bg" style="margin-left: 2%;">{{ getObjCounts.admins }}</span><span :style="getObjCounts.academicYear > 0 ? 'display: block;line-height:18px' : 'color: darkgray' " >Staff Management</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="margin-top: -12px;"></div>
                  Manage Teachers, Admins & Roles and Setup Departments
                 
                </div>
              </div>
            </div>
            <div class="col-md-4" :style="getObjCounts.teacher == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="getObjCounts.teacher > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                 @click.prevent="redirectRoute('/admission/department')"
              >
                <div class="admcount_lft blu_bg"> 
                  <span class="steps">Step 3</span>
                  <span class="admcount org_bg">{{ getObjCounts.schoolType }}</span
                  >
                  <!-- <span class="admcount org_bg" style="margin-left: 2%;">{{ getObjCounts.departMent }}</span> -->
                  <span :style="getObjCounts.teacher > 0 ? 'display: block;line-height:18px' : 'color: darkgray' " >School Type </span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="top: 2px;"></div>
                  Choose your School Type 
                  <!-- School Type | Department <br /><span
                    >[Choose your School Type and Setup Departments]</span
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
           
            <!-- <div class="col-md-4" :style="getObjCounts.class == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="getObjCounts.class > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "

                 @click.prevent="redirectRoute('/admission/new')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 3</span>
                  <span class="admcount org_bg">{{ getObjCounts.admission }}</span
                  ><span :style="getObjCounts.class > 0 ? 'display: block;line-height:18px' : 'color: darkgray' ">Manage Grade Settings</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Grade Settings
                </div>
              </div>
            </div> -->
           
              <div class="col-md-4" :style="getObjCounts.schoolType == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="getObjCounts.schoolType > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "
                 @click.prevent="redirectRoute('/admission/class')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 4</span>
                  <span class="admcount org_bg">{{ getObjCounts.class }}</span>
                  <span class="admcount org_bg" style="margin-left: 4px;">{{ getObjCounts.section || 0 }}</span>
                  <!-- <span class="admcount org_bg" style="margin-left: 2%;">{{ getObjCounts.subject }}</span> -->
                  <span :style="getObjCounts.schoolType > 0 ? 'display: block;line-height:18px' : 'color: darkgray' " > Class | Classrooms</span>
                  <!-- <span class="admcount org_bg">{{ getObjCounts.class }}</span
                  ><span class="admcount org_bg" style="margin-left: 2%;">{{ getObjCounts.section }}</span><span :style="getObjCounts.schoolType > 0 ? 'display: block;line-height:18px' : 'color: darkgray;line-height:18px' " >ManageClass/       Classrooms/  Classrooms</span> -->
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="margin-top: -12px;"></div>
                  Manage Class, Classrooms and Subjects
                  <!-- Manage your Class/Classrooms as per your Standards <br /><span
                    >[For eg. LKG, UKG, Ist Std, IInd Std etc]</span
                  > -->
                </div>
              </div>
            </div>
            <div class="col-md-4" :style="getObjCounts.class == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                style="padding: 0;"

                 @click.prevent="redirectRoute('/admission/term/setting')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 5</span>
                  <span class="admcount org_bg">{{ getObjCounts.term }}</span
                  ><span :style="getObjCounts.term > 0 ? 'display: block;line-height:18px' : 'color: darkgray' ">Grade | Terms</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="margin-top: -12px;"></div>
                  Manage Grade Settings and Terms

                  <!-- <span>[Manage Grade Settings and Terms]</span> -->
                </div>
              </div>
            </div>
            <div class="col-md-4" :style="getObjCounts.term == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1" :style="getObjCounts.class > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "

                 @click.prevent="redirectRoute('/admission/student')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 6</span>
                  <span class="admcount org_bg">{{ getObjCounts.student }}</span
                  ><span :style="getObjCounts.class > 0 ? 'display: block;line-height:18px' : 'color: darkgray' " >Student Management</span>
                </div>
                <div class="admcount_rgt" style="margin-top: 10px;">
                  <div class="triangle-left" style="margin-top: -12px;"></div>
                  Create & Manage Student Information
                </div>
              </div>
            </div>
            
           <!-- <div class="col-md-4" :style="getObjCounts.class == 0 ? 'cursor: not-allowed' : '' ">
              <div
                class="white-box m-2 white-box1"
                :style="getObjCounts.class > 0 ? 'padding: 0' : 'padding: 0;pointer-events: none;cursor: not-allowed' "

                 @click.prevent="redirectRoute('/admission/new')"
              >
                <div class="admcount_lft blu_bg">
                  <span class="steps">Step 6</span>
                  <span class="admcount org_bg">{{ getObjCounts.admission }}</span
                  ><span :style="getObjCounts.class > 0 ? 'display: block;line-height:18px' : 'color: darkgray' ">Manage Admissions</span>
                </div>
                <div class="admcount_rgt">
                  <div class="triangle-left"></div>
                  Manage Admissions process for the current year
                  <span>[the current annual period <br />2021 - 20]</span>
                </div>
              </div>
            </div> -->
          </div>
  </div>
  <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
       
        </div>
</template>
<script>

import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "vi-admission-home",
 
  data() {
    return {
      showToast: false,
      viLoader: false,
      isEnableBranch: false,
      count: {
        academicYear: 0,
        admission: 0,
        class: 0,
        departMent: 0,
        student: 0,
        teacher: 0,
        term: 0,
        grade: 0
      }
    };
  },
  created() {
    this.initComponent();
    this.getAllObjectCounts();
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getObjCounts() {
      return this.$store.getters.getAdmissionObjCounts;
      },
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
       // this.userDetail = userData.userDetails;
       // this.cloneUserDetails = { ...userData.userDetails };
       
        if (userData.userDetails.branch) {

          if(userData.userDetails.profile.name == 'School Admin' && userData.userDetails.branch.apiName == "main_branch"){
                 this.isEnableBranch = true
          }
          
         
        }
        //this.selectedBranch = localStorage.getItem("currentBranch");
      }
    },

    redirectRoute(vipath) {
      this.showToast = true
     // this.viLoader = true;
      if(this.$route.path !== vipath) {
        this.$router.push({ path: vipath })
      };
      this.showToast = false
     // this.viLoader = false;
    },
    openYouTubePopUp(){
      this.showToast = true,
     this.$bvModal.show("modalOne")
      this.showToast = false
    },
     redirectRouteToViewSchool(){

          let userData = secureUI.sessionGet("user");
          
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
            window.open(`https://${userData.userDetails.account.domainName}.vidhyaan.com/search-school/overview/?schoolName=${userData.userDetails.account.accountName}&accId=${userData.userDetails.account.accountId}`);


          }

    },
      async getAllObjectCounts() {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
            this.viLoader = true;

            let isDataFetch = this.$store.getters.getFetchAdmissionObjCounts
             if(!isDataFetch || isDataFetch == undefined){
                const response = await ViService.viXGet(
                  "/admission/getAllObjectCounts",
                  userData.token
                );
                if (response.isSuccess) {
                  const objResult = secureUI.secureGet(response.data);
                  
                  let adminCount = 0

                  //  if(userData.userDetails.branch.isAdmin && (userData.userDetails.branch._id == localStorage.getItem("currentBranch"))){
                  //     let adminOnly = objResult.admins.filter( x => x.profile.name == 'School Admin')
                  //     adminCount = adminOnly.length

                  //   }else{
                    const filterUser = objResult.admins.filter( x => x.branch && x.branch._id == localStorage.getItem("currentBranch"))
                    let particularUserList = filterUser.filter( x => x.profile.name == 'School Admin')
                     adminCount = particularUserList.length
                  //  }

                  this.$store.dispatch('setAdmissionObjCOunt', {

                  academicYear : objResult.academicYear ? objResult.academicYear : 0,
                  admission : objResult.admission ? objResult.admission : 0,
                  class : objResult.class ? objResult.class : 0,
                  departMent : objResult.departMent ? objResult.departMent : 0,
                  student : objResult.student ? objResult.student : 0,
                  teacher : objResult.teacher ? objResult.teacher : 0,
                  schoolType : objResult.schoolType ? objResult.schoolType : 0,
                  term : objResult.term ? objResult.term : 0,
                  grade : objResult.grade ? objResult.grade : 0,
                  admins : adminCount,
                  section: objResult.section ? objResult.section : 0,
                  subject: objResult.subject ? objResult.subject : 0
                  });
                  this.$store.dispatch('setFetchAdmissionObjCount',true)
                  

                  this.$toasted.success(response.message);
                } else {
                  this.$toasted.error(response.message);
                  if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
            }
                }

              }
             this.viLoader = false;

          }
        }
    
  },
   components: {
    ViSpinner
  },
};
</script>

<style lang="scss" scoped></style>